.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 300ms ease-in-out;
}

.modal {
  max-width: 1400px;
  width: 100%;
  max-height: 90vh;
  background-color: var(--color-white);
  padding: 48px;
  border-radius: 16px;
  transform: translateY(20px);
  transition: transform 300ms ease-in-out;
  overflow-y: auto;
}
.modalMessages {
  overflow-y: hidden;
}

.modal:focus-visible {
  outline: none;
}

.modalContent > *:not(:last-child) {
  margin-bottom: 26px;
}

.modalHeader {
  display: flex;
  align-items: center;
}

.modalHeader > *:not(:last-child) {
  margin-right: 16px;
}

.modalTitle {
  font-size: var(--fs-h1);
}

.modalClose {
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
}

.modalTabs > *:not(:last-child) {
  margin-bottom: 20px;
}
.modalTabsMessages > *:not(:last-child) {
  margin-bottom: 5px;
}
.modalTabsNav {
  display: flex;
}
.modalTabsNav > *:not(:last-child) {
  margin-right: 24px;
}

.modalTableWrapper {
  overflow-x: auto;
}
.modalTabsContent {
  overflow-x: hidden;
}
@media (max-width: 1400px) {
  .modalTableWrapper {
    margin-right: -12px;
    margin-left: -12px;
  }

  .modalTableWrapper > * {
    margin-left: 12px;
  }
}

@media (max-width: 1200px) {
  .modal {
    padding: 32px;
  }
}

@media (max-width: 768px) {
  .modal {
    padding: 24px 12px;
    border-radius: 12px;
  }
  .modalContent > *:not(:last-child) {
    margin-bottom: 32px;
  }
  .modalTabs > *:not(:last-child) {
    margin-bottom: 24px;
  }
  .modalTabsNav > *:not(:last-child) {
    margin-right: 12px;
  }
}

.board > *:not(:last-child) {
  margin-bottom: 48px;
}

.boardTitle {
  font-size: var(--fs-h1);
}

@media (max-width: 768px) {
  .board > *:not(:last-child) {
    margin-bottom: 40px;
  }
}

:global(div.dt-container) {
  overflow-x: hidden;
}

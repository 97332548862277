table.dataTable {
  /* max-width: 1400px; */
  width: calc(100% - 4px) !important;
  min-width: 1280px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey);
  border-radius: 12px;
}

table.dataTable > thead > tr > th.dt-orderable-asc,
table.dataTable > thead > tr > th.dt-orderable-desc {
  color: var(--color-grey-dark);
  font-size: var(--fs-very-small);
  line-height: 1.2;
  font-weight: 500;
  padding: 16px 14px 16px 8px;
  border-bottom-color: var(--color-grey);
  transition: 0.3s ease-in-out;
  word-break: break-word;
  overflow-wrap: anywhere;
  text-align: center;
}

table.dataTable > thead > tr > th.dt-orderable-asc:hover,
table.dataTable > thead > tr > th.dt-orderable-desc:hover {
  color: var(--color-black);
  outline: none;
}

table.dataTable > thead > tr > th:first-child {
  padding-left: 12px;
}

table.dataTable > thead > tr > th:last-child {
  padding-right: 12px;
}

table.dataTable > thead > tr > th.dt-orderable-asc > span.dt-column-order,
table.dataTable > thead > tr > th.dt-orderable-desc > span.dt-column-order {
  right: 1px;
}

table.dataTable > tbody > tr > td {
  font-size: var(--fs-small);
  font-weight: 700;
  padding: 0;
  text-align: center !important;
  vertical-align: middle;
  position: relative;
}
table.dataTable > tbody > tr > td > div {
  padding: 16px 8px;
}
table.dataTable > tbody > tr > td:first-child {
  text-align: left !important;
}

table.dataTable > tbody > tr > td.thin {
  font-weight: 400;
}

table.dataTable > tbody > tr > td:not([class]) {
  color: var(--color-black-light);
}

table.dataTable > tbody > tr > td > div > span {
  font-size: var(--fs-very-small);
}

table.dataTable > tbody > tr > td:not([class]) > div > span,
table.dataTable > tbody > tr > td.sorting_1 > div > span,
table.dataTable > tbody > tr > td[class=""] > div > span {
  color: var(--color-grey-dark);
  font-weight: 400;
}

div.dt-container.dt-empty-footer table.dataTable > tbody > tr:last-child > td {
  border-bottom: none;
}

table.dataTable > tbody > tr:not(:last-child) > td {
  border-bottom: 1px solid var(--color-grey);
}

table.dataTable > tbody > tr > td:first-child {
  padding-left: 12px;
  max-width: 144px;
}

table.dataTable > tbody > tr > td:last-child {
  padding-right: 12px;
}

table.dataTable > tbody > tr > td > .info {
  display: flex;
  align-items: center;
  line-height: 1.1;
  cursor: pointer;
}

table.dataTable > tbody > tr > td > .info span {
  color: var(--color-main) !important;
  font-size: var(--fs-small);
  font-weight: 700 !important;
  text-decoration: underline;
  margin-left: 8px;
  word-wrap: break-word;
  width: 100%;
}

@media (max-width: 1400px) {
  table.dataTable {
    max-width: unset;
    min-width: 1440px;
  }
}

.box {
  background-color: var(--color-white);
  padding: 32px;
  border: 1px solid var(--color-grey);
  border-radius: 12px;
}

.box > *:not(:last-child) {
  margin-bottom: 24px;
}

.boxTitle {
  font-size: var(--fs-h2);
}

.boxCaption {
  color: var(--color-grey-dark);
}

.boxContent > *:not(:last-child) {
  margin-bottom: 16px;
}

.boxText {
  color: var(--color-black-light);
  font-size: var(--fs-h2);
  font-weight: 600;
}

@media (max-width: 1200px) {
  .box {
    padding: 24px;
  }
}

@media (max-width: 768px) {
  .box {
    padding: 12px;
    border-radius: 8px;
  }

  .box > *:not(:last-child) {
    margin-bottom: 16px;
  }
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content.ReactModal__Content--after-open {
  transform: translateY(0);
}

.ReactModal__Content.ReactModal__Content--before-close {
  transform: translateY(20px);
}
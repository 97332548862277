.table > *:not(:last-child) {
  margin-bottom: 24px;
}

.tableOptions {
  display: flex;
  align-items: center;
}

.tableOptions > *:not(:last-child) {
  margin-right: 24px;
}

.tableFilters {
  width: 100%;
}

.tableExport {
  flex-shrink: 0;
  gap: 5px;
  display: flex;
}

.tableExportHidden {
  display: none;
}

.tableWrapper {
  overflow-x: auto;
}

:global(table.dataTable > tbody > tr > td > .info).warningTitle span {
  color: red !important;
}
:global(table.dataTable > tbody > tr > td > .info).infoTitle span {
  color: #ffc600 !important;
}
:global(table.dataTable > tbody > tr > td) .warningBackground {
  background: #ff8fa2;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
:global(table.dataTable > tbody > tr > td) .percent {
  margin: 0;
  padding: 0;
}
:global(table.dataTable > tbody > tr > td > div).warningCell {
  color: #eb0000;
  font-weight: 900;
  margin: 0 auto;
  position: relative;
}
:global(table.dataTable > tbody > tr > td > div).infoCell {
  border-bottom: 3px solid #ffc600 !important;
}
@media (max-width: 1400px) {
  .tableWrapper {
    margin-right: -12px;
    margin-left: -12px;
  }

  .tableWrapper > * {
    margin-left: 12px;
  }
}

@media (max-width: 768px) {
  .tableOptions {
    flex-direction: column;
  }
  .tableOptions > *:not(:last-child) {
    margin: 0 0 16px;
  }
  .tableExport {
    margin-left: auto;
  }
}

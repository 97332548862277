.filters {
  display: flex;
  align-items: center;
}

.filters > *:not(:last-child) {
  margin-right: 40px;
}

.filtersFields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  align-items: center;
  width: 100%;
}

.filtersField {
  width: 100%;
}

.filtersAction {
  flex-shrink: 0;
  margin-left: auto;
}

@media (max-width: 1200px) {
  .filters > *:not(:last-child) {
    margin-right: 32px;
  }

  .filtersFields {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
}

@media (max-width: 992px) {
  .filters {
    flex-direction: column;
    align-items: flex-start;
  }

  .filters > *:not(:last-child) {
    margin-bottom: 16px;
  }

  .filtersFields {
    grid-gap: 16px;
  }
}

@media (max-width: 768px) {
  .filtersFields {
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }

  .filtersAction {
    width: 100%;
    margin-left: unset;
  }

  .filtersAction > * {
    width: 100%;
  }
}
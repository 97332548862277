@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  --color-black: #101828;
  --color-black-light: #344054;
  --color-white: #ffffff;
  --color-grey: #d0d5dd;
  --color-grey-light: #f7f7f9;
  --color-grey-dark: #667085;
  --color-grey-shadow: #f2f4f7;
  --color-main: #af56d9;
  --color-main-dark: #6941c6;
  --color-main-light: #e9d7fe;
  --color-main-shadow: #f4ebff;

  --color-green: #439c00;
  --color-orange: #ff7300;
  --color-red: #ec2011;

  --fs-h1: 30px;
  --fs-h2: 22px;
  --fs-big: 18px;
  --fs-base: 17px;
  --fs-small: 14px;
  --fs-very-small: 12px;
}

@media (max-width: 1200px) {
  :root {
    --fs-h1: 28px;
    --fs-h2: 22px;
  }
}

@media (max-width: 768px) {
  :root {
    --fs-h1: 24px;
    --fs-h2: 20px;
    --fs-big: 18px;
    --fs-base: 16px;
    --fs-small: 14px;
  }
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--color-grey-light);
  color: var(--color-black);
  font-family: "Inter", sans-serif;
  font-size: var(--fs-base);
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  line-height: 1.4;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  line-height: 1.2;
  font-weight: 600;
  word-break: break-word;
  overflow-wrap: anywhere;
  margin-block: 0;
}

svg,
img {
  display: block;
  max-width: 100%;
  height: auto;
}

.apexcharts-canvas {
  margin: 0 auto;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.text-green {
  color: var(--color-green);
}

.text-orange {
  color: var(--color-orange);
}

.text-red {
  color: var(--color-red);
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
}

.line-clamp--1 {
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.line-clamp--2 {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.apexcharts-legend {
  overflow: unset !important;
}

.info .warning_text {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 11px;
  text-align: center;
  line-height: 12px;
  margin-top: 3px;
  color: red !important;
}
.info .warning_icon {
  text-decoration: none !important;
  margin-left: 8px !important;
  font-size: 18px !important;
}
::-webkit-scrollbar {
  width: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: #a5a5a5 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #807f7f !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 10px !important;
}

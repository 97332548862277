.page {
  padding: 24px 0 40px;
}

.main {
  padding: 48px 0 80px;
}

.container {
  max-width: 1424px;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .page {
    padding: 12px 0;
  }

  .main {
    padding: 24px 0 40px;
  }
}
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 408px;
    width: 100%;
    padding: 0 12px;
    margin: auto;
}

.wrapper > *:not(:last-child) {
    margin-bottom: 40px;
}

.logo {
    display: flex;
    align-items: center;
}

.logo svg {
    width: 48px;
    height: 48px;
}

.logo > *:not(:last-child) {
    margin-right: 16px;
}

.logoText {
    font-weight: 600;
    text-transform: uppercase;
}

.form {
    width: 100%;
    background-color: var(--color-white);
    padding: 24px;
    border-radius: 16px;
}

.form > *:not(:last-child) {
    margin-bottom: 24px;
}

.formTitle {
    font-size: var(--fs-h2);
    font-weight: 500;
    text-align: center;
}

.formFields > *:not(:last-child) {
    margin-bottom: 24px;
}

.formFields > button {
    width: 100%;
}

@media (max-width: 768px) {
    .wrapper > *:not(:last-child) {
        margin-bottom: 24px;
    }

    .logo > *:not(:last-child) {
        margin-right: 12px;
    }

    .logo svg {
        width: 40px;
        height: 40px;
    }

    .form {
        padding: 12px 16px;
    }

    .form > *:not(:last-child) {
        margin-bottom: 16px;
    }
}



.items > *:not(:last-child) {
  margin-bottom: 24px;
}

.item > *:not(:last-child) {
  margin-bottom: 8px;
}

.itemMain {
  display: flex;
  align-items: center;
}

.itemMain > *:not(:last-child) {
  margin-right: 8px;
}

.itemName {
  color: var(--color-black-light);
}

.itemValue {
  color: var(--color-black-light);
  font-size: var(--fs-h2);
  font-weight: 600;
  margin-left: auto;
}

.itemPercent {
  color: var(--color-grey-dark);
  font-size: var(--fs-small);
}

.itemProgress {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: var(--color-grey);
  border-radius: 4px;
  overflow: hidden;
}

.itemProgressValue {
  height: 100%;
  background-color: var(--color-main);
  border-radius: 4px;
}

@media (max-width: 992px) {
  .items > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media (max-width: 576px) {
  .items > *:not(:last-child) {
    margin-bottom: 8px;
  }

  .item > *:not(:last-child) {
    margin-bottom: 4px;
  }
}


.footer {
  background-color: var(--color-white);
  padding: 24px;
  border-radius: 24px;
}

.footerCopyright {
  color: var(--color-grey-dark);
  font-weight: 600;
  text-align: center;
}

@media (max-width: 768px) {
  .footer {
    padding: 12px;
    border-radius: 12px;
  }
}
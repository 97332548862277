.report > *:not(:last-child) {
    margin-bottom: 48px;
}

.reportAction {
    margin-left: auto;
}

.reportRows > *:not(:last-child) {
    margin-bottom: 40px;
}

.reportRow {
    display: grid;
    grid-gap: 16px;
}

.reportCol {
    display: flex;
    flex-direction: column;
}

.reportCol > * {
    flex-grow: 1;
}

.reportCol > *:not(:last-child) {
    margin-bottom: 16px;
}

.reportFooter {
    display: flex;
}

.columns-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.columns-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.columns-1 {
    grid-template-columns: 1fr;
}

@media (max-width: 1200px) {
    .reportRow {
        grid-gap: 12px;
    }

    .reportCol > *:not(:last-child) {
        margin-bottom: 12px;
    }
}

@media (max-width: 992px) {
    .columns-3 {
        grid-template-columns: 1fr;
    }
    .columns-2 {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .report > *:not(:last-child) {
        margin-bottom: 24px;
    }
}

@media (max-width: 576px) {
    .reportHeader {
        flex-direction: column;
        align-items: flex-start;
    }

    .reportHeader > *:not(:last-child) {
        margin-bottom: 8px;
    }

    .reportAction {
        width: 100%;
        margin-left: unset;
    }

    .reportAction > * {
        width: 100%;
    }
}
.addChat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 460px) {
  .addChat {
    flex-direction: column;
  }
}
.addChatButton {
  margin-right: 10px;
}
.selectChat {
  width: 400px;
}

.input {
  margin: 15px 0;
}
.popupContainer {
  max-width: 550px;
}

.text {
  text-align: center;
}

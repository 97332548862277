.header {
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    padding: 24px;
    border-radius: 24px;
}

.header > *:not(:last-child) {
    margin-right: 40px;
}

.headerLogo {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.headerLogo > *:not(:first-child) {
    margin-left: 16px;
}

.headerLogo svg {
    width: 48px;
    height: 48px;
}

.headerLogoText {
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
}

.headerLogoText span {
    color: var(--color-main)
}

.headerMenu > *:not(:last-child) {
    margin-right: 24px;
}

.headerUser {
    margin-left: auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.headerAction {
    flex-shrink: 0;
}

@media (max-width: 1200px) {
    .header {
        padding: 16px;
    }
    .header > *:not(:last-child) {
        margin-right: 24px;
    }
    .headerMenu > *:not(:last-child) {
        margin-right: 16px;
    }
}

@media (max-width: 768px) {
    .header {
        padding: 12px;
        border-radius: 12px;
    }

    .header > *:not(:last-child) {
        margin-right: 12px;
    }

    .headerLogo svg {
        width: 40px;
        height: 40px;
    }

    .headerLogoText {
        display: none;
    }

    .headerMenu {
        margin-left: auto;
    }

    .headerMenu > *:not(:last-child) {
        margin-right: 12px;
    }

    .headerUser {
        display: none;
    }

    .headerAction {
        margin-left: auto;
    }
}
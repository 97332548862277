.labels {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
}

.label > *:not(:last-child) {
  margin-bottom: 8px;
}

.labelTop {
  display: flex;
  align-items: center;
}

.labelTop > *:not(:last-child) {
  margin-right: 4px;
}

.labelValue {
  color: var(--color-black-light);
  font-weight: 600;
  font-size: var(--fs-h2);
}

.labelPercent {
  color: var(--color-grey-dark);
  font-size: var(--fs-small);
}

.labelBottom {
  display: flex;
  align-items: center;
}

.labelBottom > *:not(:last-child) {
  margin-right: 8px;
}

.labelColor {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.labelName {
  color: var(--color-grey-dark);
  font-size: var(--fs-very-small);
}

@media (max-width: 1200px) {
  .labels {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 992px) {
  .labels {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .labels {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 576px) {
  .labels {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;
  }

  .labelBottom > *:not(:last-child) {
    margin-right: 4px;
  }
}
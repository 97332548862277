.popupContainer {
  height: auto;
  min-height: 60vh;
  display: flex;
  justify-content: flex-start;
}
.popupContainer table {
  overflow: scroll;
  table-layout: fixed;
}

.popupContainer th {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
  font-weight: 600;
  font-size: 14px;
}

.popupContainer td {
  word-wrap: break-word;
  padding: 10px;
  font-size: 14px;
}
.popperList {
  background-color: #f7f7f7;
  border: 1px solid #f1eded;
}
.popperList ul {
  max-height: 600px;
  overflow-y: scroll;
}
.popperList li {
  border-bottom: 1px solid #ccc;
}
.popperList li:last-child {
  border-bottom: none;
}

:global([role="tooltip"]) {
  position: absolute;
  top: 0;
  z-index: 10;
}

.titleFilterIcon {
  transform: translate(2px, 7px);
}

.cell {
  word-wrap: break-word;
  max-width: 600px;
  font-size: 12px;
  padding: 10px;
}

.headCell {
  line-height: 14px;
}
@media (max-width: 1025px) {
  .cell {
    max-width: 500px;
  }
}
